.gallery-container {
    padding: 4em;
}
.gallery-container.age-miracle .gallery-image {
    background: url('../images/Marco-para-producto-AGE.png') no-repeat center center;
    background-size: contain;
    padding: 4em;
}

.gallery-container.bright-miracle .gallery-image {
    background: url('../images/Marco-para-producto-BRIGHT.png') no-repeat center center;
    background-size: contain;
    padding: 4em;
}