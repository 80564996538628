@import "~react-image-gallery/styles/css/image-gallery.css";
@import '~slick-carousel/slick/slick-theme.css';
@import '~slick-carousel/slick/slick.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Fonts */
@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Bold_0.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('./fonts/Gotham-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'NEXTART-Bold';
    src: url('./fonts/NEXT ART_Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'NEXTART-Regular';
    src: url('./fonts/NEXT ART_Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NEXTART-Light';
    src: url('./fonts/NEXT ART_Light.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'NEXTART-SemiBold';
    src: url('./fonts/NEXT ART_SemiBold.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

body {
  margin: 0;
  font-family: 'Gotham-Book', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-title {
  font-family: 'NEXTART-Bold', sans-serif;
}

.Modal {
    position: absolute;
    top: 7%;
    left: 40px;
    right: 40px;
    background-color: white;
    z-index: 52;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 51;
}

.bg-pinkish{
    background-color: rgb(255,245,247);
}