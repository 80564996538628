.product-name {
    font-family: NEXTART-Bold, sans-serif;
}

.product-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribuye el espacio verticalmente */
    height: 100%; /* Asegura que cada tarjeta llene el contenedor padre */
}

.product-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ayuda a empujar el botón hacia abajo */
}
