.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.bg-anti-edad {
  background-color: #ef3543;
  padding: 0.3rem;
}

.bg-anti-manchas {
  background-color: #fedbe7;
  padding: 0.3rem;
}

.card-sub-title {
  font-weight: normal !important;
  font-size: 18px !important;
}

[class^="CardTitle-sc"] {
  font-size: 1.8rem !important;
}

.horizontal:not(.timeline-circle) {
  min-width: 294px;
}

.rc-title {
  margin: 0 auto !important;
}

.rc-card-title {
  font-size: 1.2rem !important;
}

.custom-bullet-ul {
  @apply list-none pl-0;
}

.custom-bullet-ul li::before {
  content: '-';
  @apply mr-2;
}

.rc-card-subtitle {
  text-align: justify !important;
  text-justify: inter-word; /* Add this line for better justification */
  hyphens: auto; /* Add this line to enable hyphenation */
}

/* Additional custom CSS for better text justification */
.rc-card-subtitle p {
  margin: 0;
  line-height: 1.6; /* Adjust line height for better readability */
  word-spacing: 0.1em; /* Adjust word spacing if needed */
}
